import React, { useState } from "react"
import Layout from "../components/Layouts"
import styled from "styled-components"
import ClassSchedule from "../components/ClassSchedule"
import SEO from "../components/SEO"
const BottomSpacer = styled.div`
  margin-bottom: 400px;
  @media (max-width: 600px) {
    margin-bottom: 500px;
  }
  @media (max-width: 400px) {
    margin-bottom: 600px;
  }
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    
    margin: auto;
  }
`
const Title = styled.h2`
  grid-column: 1/3;
  margin: auto;
  font-weight: bold;
  
  @media (max-width: 600px) {
    grid-column: 1;
  }
`

const ClassList = styled.div`
  padding: 20px;
  max-width: 550px;
  margin: 0 auto;
`
const ColumnTitles = styled.h3`
  text-decoration: underline;
`

const FormStyle = styled.form`
  display: grid;
  width: 88%;
  margin: 0 auto;
  margin-top: 25px;
  max-height: 600px;
`
const FormInput = styled.input`
  margin: 10px;
  
`
const FormTextArea = styled.textarea`
  margin-bottom: 40px;
  
`
const FormButton = styled.button`
  margin: 0 auto;
  width: 100px;
  padding: 10px;
`
const Message = styled.h4`
  text-align: center;
  margin: 10px 0;
  color: #c73232;
`;
const ContactPage = () => {
  const [values, setValue] = useState({
    name: "",
    email: "",
    subject: "",
    mapleSyrup: "",
    message: "",
  })
  const [formSent, setFormSent] = useState(false)
  const [errors, setErrors] = useState([])

 

  function updateValue(e) {
    // check if its a number and convert
    let { value } = e.target
    if (e.target.type === "number") {
      value = parseInt(e.target.value)
    }

    setValue({
      ...values,
      [e.target.name]: value,
    })
  }
  async function handleSubmit(values) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        name: values.name,
        email: values.email,
        message: values.message,
        subject: values.subject,
      }),
    }
    fetch("https://www.jamesanderegg.com/snoc_form", requestOptions).then(
      response => {
        if (response.status === 200) {
          
          //form successful
          //clear errors
          setErrors([])
          //clear form
          setValue({
            name: "",
            email: "",
            subject: "",
            mapleSyrup: "",
            message: "",
          })
          //set form sent to true and display Success
          setFormSent(true)
        } else {
          //clear form
          setValue({
            name: "",
            email: "",
            subject: "",
            mapleSyrup: "",
            message: "",
          })
        }
      }
    )
  }

  const checkForm = e => {
    e.preventDefault()
    let errors = []
    // check if maple syrup is empty
    // check if other fields are empty

    Object.keys(values).map(value => {
      //catch the honey pot and check if it is empty. if not empty === bad
      if (value === "mapleSyrup") {
        if (values[value] !== "") {
          // Do Not submit form
          errors.push(value)
        }
        return
      } else {
        //check for empty values
        if (values[value] === "") {
          errors.push(value)
        }
      }
    })
    if (errors.length !== 0) {
      //if errors do not submit form
      setErrors(errors)
    } else {
      setErrors(errors)
      handleSubmit(values)
    }
  }

  return (
    <>
      <SEO title="Contact" description="This is the best place" children="These are the children"/>
      <Layout>
        <Grid>
          <Title>Contact us</Title>

          <ClassList>
            <ColumnTitles>Class List:</ColumnTitles>
           <ClassSchedule />
            
          </ClassList>

          <FormStyle onSubmit={e => checkForm(e)}>
            <ColumnTitles>Send us an email:</ColumnTitles>
           
            <FormInput
              type="text"
              name="name"
              id="name"
              value={values.name}
              placeholder="Enter your name"
              onChange={updateValue}
            />
          

            <FormInput
              type="email"
              name="email"
              id="email"
              placeholder="Enter your email"
              value={values.email}
              onChange={updateValue}
            />
            
            <FormInput
              type="text"
              name="subject"
              id="subject"
              placeholder="Enter the subject"
              value={values.subject}
              onChange={updateValue}
            />
            <input
              type="mapleSyrup"
              name="mapleSyrup"
              id="mapleSyrup"
              value={values.mapleSyrup}
              onChange={updateValue}
              className="mapleSyrup"
              style={{ display: "none" }}
            />
            
            <FormTextArea
              name="message"
              id="message"
              placeholder="Type your Message here. If inquiring about a class, please include the title of the class in your message. "
              rows="10"
              value={values.message}
              onChange={updateValue}
            />
            
            <FormButton className="submit-button" type="submit">
              Send
            </FormButton>
          </FormStyle>
          {formSent ? <Message>Thank you, your message has been sent!</Message> : null}
          {errors.length !== 0
            ? errors.map((error, i) => (
                <Message key={i}>Please fill in the {error} field.</Message>
              ))
            : null}
        </Grid>
        <BottomSpacer/>
      </Layout>
    </>
  )
}
export default ContactPage
